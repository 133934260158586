$threads-font-path: "~plaid-threads/fonts";
@import "~plaid-threads/scss/typography";
@import "~plaid-threads/scss/variables";

* {
  box-sizing: border-box;
  background-color: #F7F7F7;
  border-width:0px;

  @media screen and (max-width:"768px") {
    margin: 0;
  }
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 70 * $unit;
  max-width: 84 * $unit;
  margin: 0 auto;

  @media screen and (max-width:"768px") {
    width: 100%;
    min-width: unset;
    max-width: unset;
    margin: 0;
  }
}

#sso {
  width: 650px;
  height: 565px;
}